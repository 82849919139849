#copyright-info{
  @include span(6);
  .block__content{
    font-size: 13px;
  }
}
@include breakpoint($mobile){
  #copyright-info{
    @include span(12);
    margin-bottom: 10px;
  }
}