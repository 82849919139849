#top-menu{
  float: right;
  ul{
    margin: 0;
    li{
      float: left;
      margin-bottom: 0;
      > a,
      > .nolink{
        text-transform: uppercase;
        color: $totally_white;
        margin-right: 20px;
        font-size: 12px;
        letter-spacing: 0.24px;
        display: inline-block;
        line-height: 30px;
      }
    }
  }
}
@include breakpoint($mobile){

}