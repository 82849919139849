#social{
  .block__title{
    margin-bottom: 21px;
  }
  .block__content{
    overflow: hidden;
    padding-bottom: 2px;
    a{
      width: 30px;
      height: 30px;
      display: inline-block;
      float: left;
      position: relative;
      top: 0;
      transition: top 0.1s ease;
      margin-right: 11px;
      &.sprite-facebook{
        background-position: -170px -86px;
      }
      &.sprite-youtube{
        background-position: -329px -96px;
      }
      &.insta{
        background-image: url(/sites/all/themes/kolibioti/images/inst.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
      }
      &:hover{
        top: 2px;
      }
    }
  }
}