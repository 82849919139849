body.page-node-950{
  .node__content{
    overflow: hidden;
    tr{
      &:last-child{
        td{
          padding-bottom: 0;
        }
      }
    }
    td{
      padding-bottom: 10px;
      &:first-child{
        padding-right: 5px;
      }
      &:nth-child(2){
        padding-left: 5px;
      }
      a{
        color: inherit;
        transition: color 0.25s ease;
        &:hover{
          color: $highlighted_orange;
        }
      }
    }
  }
  .half-left{
    @include span(5 (gutters: 1));
    input[type="text"],
    input[type="email"],
    textarea{
      width: 100%;
      box-sizing: border-box;
    }
  }
  .half-right{
    @include span(7 (gutters: 1) last);
  }
  .form-actions{
    text-align: right;
  }
  .map-container{
    position: relative;
    padding-bottom: 75%;
    margin-bottom: 1em;
    border: 2px solid $normal_gray;
    iframe{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: none;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  @include breakpoint($mobile){
    .half-left{
      @include span(12);
      input[type="text"],
      input[type="email"],
      textarea{
        width: 100%;
        box-sizing: border-box;
      }
    }
    .half-right{
      @include span(12);
      margin-bottom: 50px;
    }
  }
}