body.page-node-953{
  .l-page{
    .l-main{
      .l-content{
        article{
          display: inline-block;
          width: 100%;
          .node__content{
            .field{
              .field__items{
                .field__item{
                  .company-full{
                    margin-bottom: 50px;
                  }
                  .company-wrapper{
                    width: 100%;
                    display: block;
                    max-width: 1000px;
                    margin: 0 auto;
                    .company-left{
                      @include span(6 (gutters:0) last);
                      img{
                        width: 100%;
                        object-fit: cover;
                      }
                    }
                    .company-right{
                      @include span(6 (gutters:0) );
                      p{
                        margin-top: 40px;
                        padding-right: 40px;
                        width: calc(100% - 40px);
                      }
                      img{
                        width: 100%;
                        object-fit: cover;
                      }
                    }
                    &.second{
                      clear: both;
                      .company-left{
                        @include span(6 (gutters:0));
                      }
                      .company-right{
                        @include span(6 (gutters:0) last);
                        p{
                          margin-top: 40px;
                          padding-left: 40px;
                          width: calc(100% - 40px);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      #block-block-17{
        clear: both;
        max-width: 1000px;
        margin: 50px auto;
        h2{
          padding-top: 40px;
          margin-bottom: 30px;
          font-size: 25px;
        }
        .block{
          .company-producttab-left{

          }
          .company-producttab-right{

          }
        }
      }
    }
  }
}
@include breakpoint($mobile){
  body.page-node-953{
    .l-page{
      .l-main{
        .l-content{
          article{
            .node__content{
              .field{
                .field__items{
                  .field__item{
                    .company-wrapper{
                      .company-left{
                      width: 100%;
                      }
                      .company-right{
                        width: 100%;
                        p{
                          padding-right: 0;
                          width: 100%;
                        }
                      }
                      &.second{
                        .company-left{
                          width: 100%;
                        }
                        .company-right{
                          width: 100%;
                          p{
                            padding-left: 0;
                            width: 100%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #block-block-17{
          .block{
          }
        }
      }
    }
  }
}

#πιστοποίηση-iso{
  margin-top: 50px;
  padding-top: 50px;
  @extend .clearfix;
  .block__title{
    text-align: center;
    font-size: 30px;
    color: #333333;
    letter-spacing: 0.8px;
    line-height: calc(1em + 7px);
    position: relative;
    width: fit-content;
    margin: auto;
    margin-bottom: 60px;
    &:before{
      content: '';
      position: absolute;
      bottom: -16px;
      left: 50%;
      width: 40%;
      right: 0;
      height: 3px;
      box-sizing: border-box;
      border-bottom: 3px solid #ff9933;
      transform: translateX(-50%);
    }
  }
  .third{
    @include span(4 (gutters: 1));
    margin-bottom: 4.34783%;
    &:nth-child(3n + 3) {
      @include span(4 (gutters: 1) last);
    }

    @include breakpoint($mobile){
      @include span(6 (gutters: 1));
      &:nth-child(3n + 3) {
        @include span(6 (gutters: 1));
      }
      &:nth-child(2n + 2) {
        @include span(6 (gutters: 1) last);
      }
    }

    @include breakpoint($small_mobile){
      @include span(12);
      &:nth-child(3n + 3) {
        @include span(12);
      }
      &:nth-child(2n + 2) {
        @include span(12);
      }
    }
  }
  a{
    display: inline-block;
  }
  img{
    vertical-align: bottom;
    transition: filter 0.2s ease;
    &:hover{
      filter: brightness(1.1);
    }
  }
}
