#newsletter{
  .block__title{
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.4px;
    margin-bottom: 13px;
  }
  form{
    overflow: hidden;
    border: 2px solid #fff;
    transition: border-color 0.25s ease;
    &:invalid{
      border-color: crimson;
    }
  }
  input[type="email"]{
    float: left;
    border: none;
    background: none;
    color: $totally_white;
    font-size: 13px;
    line-height: 1;
    @include placeholder($totally_white, 1, 400);
    padding: 16px 9px 17px;
    width: calc(100% - 55px);
    &:invalid{
      outline: none;
      box-shadow: none;
    }
  }
  input[type="submit"]{
    float: left;
    border: none;
    color: transparent;
    font-size: 13px;
    line-height: 1;
    padding: 16px 15px 17px 18px;
    background-color: transparent;
    @extend .sprite-chevron-right;
    height: auto;
    background-position: 0px -79px;
    cursor: pointer;
    transition: background-position-x 0.25s ease;
    &:hover{
      background-position-x: 4px;
    }
  }
}