#top-phone{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: inline-block;
  .block__content{
    display: inline-block;
    > a{
      @extend .header-font;
      color: white;
      font-weight: 700;
      vertical-align: bottom;
      display: inline-block;
      padding: 2px 0 5px;
    }
  }
  @include breakpoint($mobile){
    left: 0;
    transform: none;
    a.icon-before-phone{
      height: 30px;
      line-height: 27px;
      padding-left: 20px;
      &:before{
        top: 2px;
        left: 0;
        position: absolute;
      }
    }
  }
}

.icon-before-phone{
  line-height: 23px;
  &:before{
    @extend .sprite-phone;
    content: '';
    display: inline-block;
    vertical-align: bottom;
    margin-right: 4px;
  }
}