.view-machinery{
  .view-header{
    margin-bottom: 30px;
    @extend .clearfix;
    .category-header{
      margin-bottom: 47px;
      //max-width: 750px;
      p{
        font-size: 16px;
        @media(max-width: 768px){
          font-size: 14px;
        }
      }
      .big-title{
        margin-bottom: 27px;
      }
    }
    .group{
      padding-bottom: 30px;
      border-bottom: 2px solid $normal_gray;
      margin-bottom: 30px;
    }
  }
  > .view-content{
    overflow: hidden;
    .grid-product-item{
      box-sizing: border-box;
      margin-bottom: 4.50466%;
      @include breakpoint(min-width $tablet +1){
        @include span(4.0001 (gutters: 1.0715));
        &:nth-child(3n + 3){
          @include span(4.0001 (gutters:  1.0715) last);
        }
      }
    }
    .pager{
      margin-top: -30px;
      margin-bottom: 46px;
    }
  }
}
@include breakpoint($tablet $mobile + 1){
  .view-machinery{
    .view-header{

      .category-header{

        .big-title{

        }
      }
      .group{

      }
    }
    > .view-content{

      .grid-product-item{
        @include span(1 of 2 (gutters: 0.05));
        margin-bottom: 2.43902%;;
        &:nth-child(2n){
          @include span(1 of 2 (gutters: 0.05) last);
        }
      }
      .pager{
        margin-top: 0;
      }
    }
  }
}
@include breakpoint($mobile){
  .view-machinery{
    .view-header{
      margin-left: 0;
      margin-right: 0;
      .category-header{

        .big-title{

        }
      }
      .group{

      }
      .pager{
        text-align: right;
        width: 100%;
        padding: 20px 0 20px 0;
        li{
          float: unset;
        }
      }
    }
    > .view-content{
      overflow: unset;
      width: 100%;
      display: inline-block;
      .grid-product-item{
        @include span(12);
        margin-bottom: 15px;

      }
      .pager{
        margin-top: 0;
        margin-bottom: 5px;
      }
    }
  }
}
