#site-by-gim{
  @include span(6 last);
  .block__content{
    text-align: right;
    font-size: 13px;
    a{
      color: inherit;
      transition: color 0.25s ease;
      &:hover{
        color: $main_orange;
      }
    }
  }
}
@include breakpoint($mobile){
  #site-by-gim{
    @include span(12 );
    float: left;
    .block__content{
      text-align: left;

    }
  }
}