#language-dropdown{
  float: right;
  .block__content,
  form{
    display: inline-block;
    >div{
      display: inline-block;
    }
  }
  #lang-dropdown-select-language_content{
    padding: 0 6px 0 1px;
  }
  .form-item-lang-dropdown-select,
  .select-title{
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    &.converted-processed{
      &:after{
        content: none;
      }
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: block;
      width: 29px;
      background-color: white;
      @extend .sprite-angle-down;
      height: auto;
      background-position: -80px -4px;
      transition: transform 0.5s ease;
      transform-origin: center;
    }
  }
  .select-title{
    padding: 0 29px 0 5px;
    display: inline-block;
    vertical-align: bottom;
    line-height: 30px;
    position: relative;
    z-index: 3;
    &:after{
      background-color: transparent;
    }
  }
  .lang-dropdown-select-element,
  .select-title{
    background-color: $totally_white;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    height: 30px;
    cursor: pointer;
  }
  .block-select-lang-dropdown-select-language_content{
    position: relative;
    #lang-dropdown-select-language_content-list{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: $totally_white;
      z-index: 2;
      transform: translate(0, -5px);
      transition: transform 0.25s ease;
      cursor: pointer;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
      li{
        margin: 0;
        input{
          display: none;
          &[checked="checked"] + label{
            display: none;
          }
        }
        label{
          text-transform: uppercase;
          font-size: 12px;
          line-height: 16px;
          padding: 7px 5px;
          font-weight: 400;
          text-align: left;
          cursor: pointer;
          transition: background-color 0.25s ease, color 0.25s ease;
          &:hover{
            background-color: $darker_gray;
            color: $totally_white;
          }
        }
      }
    }
    &:hover{
      #lang-dropdown-select-language_content-list{
        transform: translate(0, 0);
      }
    }
    &.open{
      .select-title{
        &:after{
          transform: rotateX(180deg);
        }
      }
      #lang-dropdown-select-language_content-list{
        transform: translate(0, 100%);
      }
    }
  }
}