#price-slider{
  width: 288px;
  display: inline-block;
  .block__title{
    margin-bottom: 10px;
  }
  .block__content{
    .search-api-ranges-widget{
      padding: 20px 0;
      .text-range,
      .range-box,
      .form-submit{
        display: none;
      }
      .range-slider{
        height: 4px;
        background: $normal_gray;
        border: none;
      }
      .ui-slider-range{
        background: $darker_gray;
        transition: width 0.1s ease, left 0.1s ease;
      }
      .ui-slider-handle{
        border-radius: 50%;
        top: 1px;
        margin-left: 0;
        transform: translate(0, -50%);
        @extend .sprite-big-dot;
        border: none;
        transition: box-shadow 0.1s ease, left 0.1s ease;
        cursor: grab;
        &:hover{
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        }
        &:first-child{
          > span{
            left: 0;
          }
        }
        &:nth-of-type(2){
          transform: translate(-100%, -50%);
          > span{
            right: 0;
          }
        }
        > span{
          position: absolute;
          top: 0;
          transform: translateY(calc(-100% - 4px));
          color: $darker_gray;
        }
      }
    }
  }
}
@include breakpoint($tablet){
  #price-slider{
    display: block;
    margin-top: 30px;
  }
}
@include breakpoint($mobile){
  #price-slider{
    width: 100%;
  }
}
