.pager{
  float: right;
  margin: 0;
  padding: 11px 0 12px 0;
  li{
    @extend .header-font;
    @extend .small-text;
    float: left;
    margin: 0;
    padding: 0;
    a,
    &.pager__item--current{
      padding: 3px 1px;
      display: inline-block;
    }
    &.pager__item--current{
      &:before{
        padding: 0 3px 0 1px;
      }
    }
    &.pager__item--previous,
    &.pager__item--previous + .pager__item,
    &.pager__item--next,
    &.pager__item:first-child{
      &:before{
        content: none;
      }
    }
    &:before{
      content: '/';
      vertical-align: bottom;
      color: $dark_gray;
      display: inline-block;
      padding: 3px 2px;
      font-size: calc(1em + 2px);
      position: relative;
      top: 1px;
    }
    &.pager__item--first,
    &.pager__item--last{
      display: none;
    }
    &.pager__item--previous,
    &.pager__item--next{
      a{
        color: transparent;
        font-size: 0;
        line-height: 0;
        padding: 3px;
        display: inline-block;
      }
    }
    &.pager__item--previous{
      padding-right: 4px;
      a{
        @extend .sprite-angle-bigger-left;
        height: 15px;
        background-position: -12px -9px;
      }
    }
    &.pager__item--next{
      padding-left: 4px;
      a{
        @extend .sprite-angle-bigger-right;
        height: 15px;
        background-position: -49px -9px;
      }
    }
  }
}