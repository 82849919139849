.node-type-machinery{
  .l-page{
    .l-main{
      main.l-content{
        max-width: none;
        margin: 0;
      }
    }
  }
  main.l-content{
    background-color: $totally_white;
    padding-bottom: 0!important;
    .node__content{
      @include container_m($site-container - 60px);
      overflow: hidden;
      margin-bottom: 120px;
      h1{
        margin-bottom: 5px;
      }
    }
    .slick-wrapper,
    .unslick{
      @include span(6.0001 (gutters: 1.0715));
      padding-top: 28px;
      .slick--display--main{
        margin-bottom: 40px;
        .media{
          position: relative;
          padding-bottom: 66.3522%;
          img{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            height: auto;
          }
          .media-icon{
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            height: auto;
            width: auto;
            padding: 15px;
            margin: 0;
            background-color: rgba($totally_white, 0.8);
            border-radius: 4px;
            transition: background-color 0.25s ease, visibility 0s linear 0.25s, opacity 0.25s linear;
            &:before{
              content: none;
            }
            &:after{
              @extend .sprite-search;
              vertical-align: bottom;
              transform: none;
              position: static;
              margin: 0;
              background-color: transparent;
            }
            &:hover{
              background-color: rgba($totally_white, 0.95);
            }
          }
          &:hover{
            .media-icon{
              transition-delay: 0s;
            }
          }
        }
      }
      .slick--display--thumbnail{
        @include prefix(1.1323);
        @include suffix(1.1323);
        position: relative;
        .slick-track{
          margin-right: -10px;
        }
        .slick__slide{
          margin-right: 10px;
          cursor: pointer;
          width: 64px;
          height: 64px;
          position: relative;
          img{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .slick__arrow{
          position: absolute;
          top: 14px;
          left: 0;
          right: 0;
          button{
            position: absolute;
            color: transparent;
            width: 40px;
            padding: 0;
            height: 50px;
            border:none;
            &:after{
              content: '';
              border-top: 2px solid $main_orange;
              border-left: 2px solid $main_orange;
              display: inline-block;
              width: 20px;
              height: 20px;
            }
            &.slick-prev{
              left: 0;
              &:after{
                transform: rotate(-45deg) translate(7px, 0px);
              }
            }
            &.slick-next{
              right: 0;
              &:after{
                transform: rotate(135deg) translate(1px, 7px);
              }
            }
          }
        }
      }
    }
  }
  .product-description-container{
    @include span(5.7415 (gutters: 1.0715) last);
    //@include suffix(0.2704);
  }
  .product-price{
    @extend .big-title;
    color: #666;
    margin-top: 0;
    margin-bottom: 24px;
  }
  .product-icons{
    margin-bottom: 25px;
    [class^="icon-above"],
    [class*=" icon-above"]{
      padding-top: 28px;
      display: inline-block;
      line-height: 1;
      margin-right: 14px;
      &:before{
        top: auto;
        bottom: calc(1em + 2px);
        left: 50%;
        transform: translateX(-50%);
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .product-body{
    margin-bottom: 30px;
  }
}

@include breakpoint($tablet){
  .node-type-machinery{
    .l-page{
      .l-main{
        main.l-content{

        }
      }
    }
    main.l-content{

      .unslick {
        @include span(12 (gutters: 1.0715));
      }
      .node__content{

        h1{

        }
      }
      .slick-wrapper{
        width: 100%;
        float: none;
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
        .slick--display--main{
          .media {
            position: relative;
            padding-bottom: 66.3522%;
          }
          .slick-media{

            img{
              left: 50%;
              transform: translateX(-50%);
            }
            .media-icon{

              &:before{

              }
              &:after{

              }
              &:hover{

              }
            }
            &:hover{
              .media-icon{

              }
            }
          }
        }
        .slick--display--thumbnail{
          padding-left: 10px;
          padding-right: 10px;
          max-width: 517px;
          margin: auto;
          .slick-track{

          }
          .slick__slide{

          }
          .slick__arrow{
            left: -25px;
            right: -25px;
            button{
              position: absolute;
              color: transparent;
              width: 40px;
              padding: 0;
              height: 50px;
              border:none;
              &:after{
                content: '';
                border-top: 2px solid $main_orange;
                border-left: 2px solid $main_orange;
                display: inline-block;
                width: 20px;
                height: 20px;
              }
              &.slick-prev{
                left: 0;
                &:after{
                  transform: rotate(-45deg) translate(7px, 0px);
                }
              }
              &.slick-next{
                right: 0;
                &:after{
                  transform: rotate(135deg) translate(1px, 7px);
                }
              }
            }
          }
        }
      }
    }
    .slick--colorbox{
      text-align: center;
    }
    .product-description-container{
      width: 100%;
      padding-left: 2.25333%;
      float: left;
      margin-top: 40px;
    }
    .product-price{

    }
    .product-icons{

      [class^="icon-above"],
      [class*=" icon-above"]{

        &:before{

        }
        &:last-child{

        }
      }
    }
    .product-body{

    }
  }
}
@include breakpoint($mobile){
  .node-type-machinery{
    .l-page{
      .l-main{
        main.l-content{

        }
      }
    }
    main.l-content{

      .node__content{
        margin-bottom: 30px;
        h1{

        }
      }
      .slick-wrapper{

        .slick--display--main{
          .slick-media{

            img{

            }
            .media-icon{

              &:before{

              }
              &:after{

              }
              &:hover{

              }
            }
            &:hover{
              .media-icon{

              }
            }
          }
        }
        .slick--display--thumbnail{
          position: relative;
          padding-left: 30px;
          padding-right: 30px;
          .slick-track{

          }
          .slick__arrow {
            left: 0;
            right: 0;
          }
          .slick__slide{
            &:last-child{
              margin-right: 0;
            }
            img{

            }
          }
          .slick__arrow{
            /*button{
              color: unset;
              text-transform:  unset;
              letter-spacing:  unset;
              font-size:  0;
              line-height:  unset;
              border:  unset;
              padding:  unset;
              transition:  unset;
              background-color:  unset;
              cursor:  unset;
              &.slick-prev{
                &:before,
                &:after{
                  content: '';
                  display: inline-block;
                  position: absolute;
                  width: 23px;
                  height: 3px;
                  background: #ff9933;

                }
                &:before{
                  transform: rotate(45deg);
                  left: -3px;
                  bottom: 45px;
                }
                &:after{
                  top: 26px;
                  transform:  rotate(-45deg);
                  left: -3px;
                }
              }
              &.slick-next{
                &:before,
                &:after{
                  content: '';
                  display: inline-block;
                  position: absolute;
                  width: 23px;
                  height: 3px;
                  background: #ff9933;

                }
                &:before{
                  transform: rotate(-45deg);
                  right: -3px;
                  bottom: 45px;
                }
                &:after{
                  top: 26px;
                  transform:  rotate(45deg);
                  right: -3px;
                }
              }
            }*/
          }
        }
      }
    }
    .slick--colorbox{

    }
    .product-description-container{

    }
    .product-price{

    }
    .product-icons{

      [class^="icon-above"],
      [class*=" icon-above"]{

        &:before{

        }
        &:last-child{

        }
      }
    }
    .product-body{

    }
  }
}
