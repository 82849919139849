.menu{
  .leaf{
    list-style: none;
  }
}

.header-font{
  font-weight: 900;
}

.tiny-title{
  @extend .header-font;
  color: $dark_gray;
  font-size: 12px;
  letter-spacing: 0.24px;
  line-height: calc(1em + 2px);
}

.small-title{
  @extend .header-font;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: calc(1em + 4px);
  margin-bottom: 10px;
}

.medium-title{
  @extend .header-font;
  font-size: 30px;
  letter-spacing: 0.6px;
  line-height: calc(1em + 6px);
  margin-bottom: 10px;
}

.big-title{
  @extend .header-font;
  font-size: 40px;
  letter-spacing: 0.8px;
  margin-bottom: 20px;
  line-height: calc(1em + 7px);
  &[class^="block-line"],
  &[class*=" block-line"]{
    &:before{
      bottom: 8px;
    }
  }
}

.small-text{
  font-size: 12px;
  letter-spacing: 0.24px;
  line-height: calc(1em + 3px);
}

.default-button{
  @extend .header-font;
  display: inline-block;
  color: $main_orange;
  text-transform: uppercase;
  letter-spacing: 0.24px;
  font-size: 12px;
  line-height: 1;
  border: 2px solid $normal_gray;
  padding: 20px 31px 21px;
  transition: border-color 0.25s ease, color 0.25s ease;
  background-color: transparent;
  cursor: pointer;
  &:hover{
    border-color: $main_orange;
    color: $saturated_orange;
  }
}

.orange-arrow{
  font-size: 0;
  color: transparent;
  line-height: 0;
  display: inline-block;
  margin-top: 11px;
  padding-right: 10px;
  &:before{
    content: '';
    @extend .sprite-long-arrow-right;
    padding: 5px 0;
    position: relative;
    left: 0;
    transition: left 0.25s ease;
    background-position-y: -193px;
  }
  &:hover{
    &:before{
      left: 5px;
    }
  }
}

.white-arrow{
  font-size: 0;
  color: transparent;
  line-height: 0;
  display: inline-block;
  margin-top: 11px;
  padding-right: 10px;
  &:before{
    content: '';
    @extend .sprite-white-long-arrow-right;
    padding: 5px 0;
    position: relative;
    left: 0;
    transition: left 0.25s ease;
    background-position-y: -230px;
  }
  &:hover{
    &:before{
      left: 5px;
    }
  }
}

[class^="icon-above"],
[class*=" icon-above"]{
  position: relative;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: bottom;
  }
}

.icon-above-sales{
  &:before{
    @extend .sprite-showroom;
  }
}

.icon-above-used{
  &:before{
    @extend .sprite-used;
  }
}

.icon-above-rent{
  &:before{
    @extend .sprite-rent;
  }
}

.icon-above-service{
  &:before{
    @extend .sprite-gear;
  }
}

.icon-above-load-capacity{
  &:before{
    @extend .sprite-load-capacity;
  }
}

.icon-above-fork-height{
  &:before{
    @extend .sprite-max-height;
  }
}

.nolink{
  cursor: default;
}

.hidden-text{
  font-size: 0;
  color: transparent;
}

.white-disc{
  background-color: $totally_white;
  border-radius: 50%;
}

.white-box{
  background-color: $totally_white;
  padding: 20px 25px;
}

.placeholder{
  position: relative;
  &:before{
    content: '';
    background-color: rgba(0,0,0,0.153);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  img{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 2;
    &.loaded{
      opacity: 1;
    }
  }
}

// .block-line-left{
//   position: relative;
//   &:before{
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 10000%;
//     transform: translate(calc(-30px - 100%), 0);
//     border-bottom: 2px solid $normal_gray;
//   }
// }

// .block-line-right{
//   @extend .block-line-left;
//   &:before{
//     left: auto;
//     right: 0;
//     transform: translate(calc(30px + 100%), 0);
//   }
// }

.table{
  display: table;
}

.table-row{
  display: table-row;
}

.table-cell{
  display: table-cell;
}

.slick-dots{
  padding: 0;
  text-align: center;
  list-style: none;
  margin-top: 20px;
  height: auto;
  line-height: 0;
  > li{
    display: inline-block;
    vertical-align: bottom;
    line-height: 0;
    margin: 0 5px 0 0;
    button{
      border: none;
      font-size: 0;
      color: transparent;
      @extend .sprite-dot;
      cursor: pointer;
      padding: 0;
    }
    &.slick-active{
      button{
        @extend .sprite-dot-active;
      }
    }
    &:last-child{
      margin: 0;
    }
  }
}

.dark-bg{
  background-color: $darker_gray;
  color: $totally_white;
  padding: 30px;
  .tiny-title{
    color: inherit;
  }
}

.orange-bg{
  @extend .dark-bg;
  background-color: $main_orange;
}


.grid-product-item{
  margin-right: 60px;
  background-color: $totally_white;
  padding: 5px;
  min-height: 500px;
  .placeholder{
    display: block;
    padding-bottom: 77.16%;
    &:before{
      background-color: transparent;
    }
  }
  .grid-product-title{
    min-height: 45px;
    @extend .grid-price;
    color: $main_orange;
    a{
      display: inline-block;
    }
  }
  .grid-price{
    @extend .header-font;
    letter-spacing: 0.38px;
    font-size: 19px;
    text-transform: uppercase;
    line-height: calc(1em + 2px);
  }
  .grid-description{
    margin: 8px 0;
    height: calc(2*(1em + 5px));
    overflow: hidden;
  }
  .characteristics{
    padding: 10px 20px;
  }
  .table{
    width: 100%;
  }
  .table-row{
    &:first-child{
      .table-cell{
        border-top: 1px solid $light_gray;
      }
    }
  }
  .table-cell{
    border-bottom: 1px solid $light_gray;
    padding: 4px 0;
    &:first-child{
      width: 132px;
    }
  }
  [class^="icon-above"],
  [class*=" icon-above"]{
    padding-top: 28px;
    display: inline-block;
    margin-top: 8px;
    line-height: 1;
    margin-right: 14px;
    &:before{
      top: auto;
      bottom: calc(1em + 2px);
      left: 50%;
      transform: translateX(-50%);
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.checkmarks-list{
  padding: 0;
  margin: 0;
  ul{
    padding: 0;
    margin: 0;
  }
  li{
    list-style: none;
    position: relative;
    padding-left: 18px;
    margin: 0 0 10px;
    &:before{
      content: '';
      @extend .sprite-checkmark;
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      background-position: -244px -7px;
    }
  }
}

.clearfix{
  &:after{
    content: '';
    clear: both;
    display: table;
  }
}

@include breakpoint($mobile){
  .medium-title{
    font-size: 25px;
  }
  .big-title{
    font-size: 34px;
  }
  .placeholder{

    &:before{

    }
    img{
      left: 50%;
      transform: translate(-50% , 0);
    }
  }
}
