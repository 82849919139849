a{
  text-decoration: none;
  color: $main_orange;
  transition: color 0.25s ease;
  vertical-align: bottom;
  &:hover{
    color: $highlighted_orange;
  }
}

h1, h2, h3, h4, h5, h6{
  @extend .header-font;
}

img{
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  &[data-lazy]{
    height: 0;
  }
}

h1{
  @extend .big-title;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea{
  padding: 16px 9px 17px;
  background-color: $totally_white;
  border: 2px solid $normal_gray;
  transition: border-color 0.25s ease;
  font-family: inherit;
  font-size: 13px;
  line-height: 1;
}

input[type="submit"],
button{
  @extend .default-button;
}