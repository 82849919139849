.page-node-949{
  .l-main{
    position: relative;
    height: 267px;
  }
  .l-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    text-align: center;
    width: calc(100% - 35px);
    padding: 0 15px;
    h1{
      font-size: 96px;
      &:before{
        content: none;
      }
    }
  }
}