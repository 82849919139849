#edit-sort{
  &.active{
    .block__content{
      form{
        box-shadow: 0 14px 18px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .block__title{
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 15px 0;
  }
  .block__content{
    position: relative;
    form{
      left: -2px;
      right: -2px;
      bottom: -2px;
      #edit-search-wrapper{
        display: none;
      }
      .views-widget-sort-sort_bef_combine{
        .views-widget{
          > div{
            display: initial !important;
            > .form-item-sort{
              > label{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}