#front-page-slider{
  margin-bottom: 30px;
  .view-content{
    padding-bottom: 60px;
    overflow: hidden;
  }
  .slick-list{
    overflow: visible;
  }
  .unslick,
  .slick-slide{
    background-color: $totally_white;
    position: relative;
    height: 604px;
  }
  .slide-text-section{
    @include span(6.635 (gutters: 1/12));
    display: inline-block;
    padding: 48px 0;
    .wrapper{
      display: table-cell;
      vertical-align: middle;
      padding: 0 340px 0 294px;
      height: calc(604px - 96px);
      div{
        margin-bottom: 20px;
      }
    }
  }
  .offset-image{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 60px);
    pointer-events: none;
  }
  .main-image{
    @include span(5.365 (gutters: 1/12) last);
    overflow: hidden;
    img{
      max-width: unset;
    }
  }
  .slick__arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    left: 0;
    right: 0;
    button{
      position: absolute;
      background: #ffffff;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0;
      line-height: 140px;
      overflow: hidden;
      color: transparent;
      transition: all 0.35s ease;
      &:hover{
        border-color: $main_orange;
      }
      &:after{
        content: '';
        position: absolute;
        background-image: url(/sites/all/themes/kolibioti/images/sprites.png);
        background-repeat: no-repeat;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 14px;
        background-color: white;
        height: auto;
        transition: transform 0.5s ease;
        -ms-transform-origin: center;
        transform-origin: center;
        left: 12px;
      }
      &.slick-prev{
        left: 5px;
        &:after{
          background-position: -13px -2px;
        }
      }
      &.slick-next{
        right: 5px;
        &:after{
          background-position: -50px -2px;
        }
      }
    }
  }
}
@include breakpoint($desktop_first){
  #front-page-slider{
    .slide-text-section{
      .wrapper{
        padding: 0 340px 0 200px;

      }
    }
    .offset-image{
      left: 53%;
      transform: translate(-47%, 60px);
    }
  }
}
@include breakpoint($tablet){
  #front-page-slider{
    .view-content{

    }
    .slick-list{

    }
    .unslick,
    .slick-slide{
      height: 520px;
    }
    .slide-text-section{
      @include span(6.635 (gutters: 1/12));
      display: inline-block;
      padding: 48px 0;
      .wrapper{
        padding: 0 340px 0 20px;
        height: calc(520px - 96px);
        div{

        }
      }
    }
    .offset-image{
      left: 56%;
      transform: translate(-44%, 60px);
      img{
        max-height: 500px;
      }
    }
    .main-image{

      img{

        max-height: 520px;
      }
    }
  }
}
@include breakpoint($bmobile){
  #front-page-slider{
    .unslick,
    .slick-slide{
      height: auto;
    }
    .slide-text-section {
      .wrapper {
        height: auto;
        div {
          display: none;
        }
      }
    }
  }
}
@include breakpoint($mobile){
  #front-page-slider{
    position: relative;
    .view-content{
      padding-bottom: 20px;
    }
    .slick-list{

    }
    .slide-text-section{
      width: 100%;
      background-color: #fff;
      padding: 10px 10px;
      box-sizing: border-box;
      .wrapper{
        padding: 20px 0px 0px 0px;
        margin: auto;
        display: block;
        max-width: 500px;
        box-sizing: border-box;
        .big-title{
          font-size: 22px;
        }
        div{

        }
        .default-button{
          padding: 15px 25px 15px;
        }
      }
    }
    .offset-image{
      display: none;
    }
    .main-image{
      width: 100%;
      img{
        max-width: 100%;
      }
    }
    .slick__arrow {
      bottom: 220px;
      transform: none;
      top: auto;
    }
  }
}

.view-machinery-carousel-front-page-video {
  margin-top: 100px;
  .trigger-video {
    max-width: 100%;
    margin: 0;
  }
  .trigger-video-out:before {
    content: unset;
  }
}

.view-machinery-carousel-front-page .video-title {
  .white-box {
    position: relative;
    z-index: 5;
    clear: both;
    min-width: 70%;
    padding-bottom: 40px;
    &:hover .medium-title {
      left: 2px;
      color: #f58220;
    }
  }
  .medium-title {
    color: #ff9933;
    margin-bottom: 0;
    position: relative;
    left: 0;
    transition: color 0.25s ease, left 0.25s ease;
  }
}
