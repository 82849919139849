body{
  background-color: $light_gray;
  .l-page{
    header{
      background-color: #fff;
      position: relative;
      .header-top{
        background-color: $darker_gray;
        .l-region--above-header{
          @include container_m($site-container - 48px);
          padding: 0 24px;
          text-align: center;
          position: relative;
          height: 30px;
        }
      }
      .l-branding{
        @include container_m($site-container - 48px);
        padding: 30px 24px 0;
        &:before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: calc((var(--window-width) - 1344px) / 2);
          height: 10px;
          background-color: $main_orange;
        }
        .header-main{
          display: table;
          width: 100%;
          position: relative;
          .site-logo{
            width: 218px;
            height: 83px;
            padding: 10px 9px 9px;
            display: table-cell;
            position: relative;
            bottom: -10px;
            z-index: 501;
            background-color: $totally_white;
            img{
              position: absolute;
              top: 10px;
              left: 9px;
            }
          }
          .l-region--navigation{
            width: calc(100% - 236px);
            display: table-cell;
            vertical-align: bottom;
            padding-bottom: 10px;
          }
        }
        &:after{
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: calc((var(--window-width) + 1325px) / 2);
          height: 10px;
          background-color: $normal_gray;
        }
      }
    }
    .l-main{
      min-height: calc(100vh - 452px);
      > .messages{
        @include container_m(900px);
        margin-top: 28px;
        &~ main.l-content{
          padding-top: 10px;
        }
      }
      .l-region--full-width-top{
        overflow: hidden;
      }
      main.l-content{
        @include container_m($site-container - 60px);
        padding-top: 68px;
        padding-bottom: 60px;
      }
    }
    footer{
      background-color: $darker_gray;
      color: $totally_white;
      .main-footer-container{
        @include container_m($site-container - 60px);
        overflow: hidden;
        padding: 60px 0 70px;
        .l-region--footer-col-1{
          @include span(3 (gutters: 205/192));
        }
        .l-region--footer-col-2{
          @include span(4 (gutters: 205/192));
        }
        .l-region--footer-col-3{
          @include span(3 (gutters: 205/192));
        }
        .l-region--footer-col-4{
          @include span(2 (gutters: 205/192) last);
        }
      }
      .l-region--copyright{
        @include container_m($site-container - 60px);
        padding: 10px 0;
        overflow: hidden;
      }
    }
  }
}
@include breakpoint(1260px){
  body{

    .l-page{
      header{

        .header-top{

          .l-region--above-header{

          }
        }
        .l-branding{

          &:before{

          }
          &:after{

          }
          .header-main{

            .site-logo{
              width: 140px;
              padding-bottom: 0;
              bottom: 0;
              img{
                left: 20px;
                width: 100%;
                position: relative;
                top: 0px;
                left: 0px;
              }
            }
            .l-region--navigation{

            }
          }
          &:after{

          }
        }
      }
      .l-main{

        .l-region--full-width-top{

        }
      }
      footer{


        .l-region--copyright{

        }
      }
    }
  }
}
@include breakpoint($tablet){
  body{

    .l-page{
      header{

        .header-top{

          .l-region--above-header{

          }
        }
        .l-branding{
          padding: 0;
          &:before{
            width: 10%;
          }
          &:after{
            width: 90%;
          }
          .header-main{

            .site-logo{
              width: 125px;
              padding-bottom: 0;
              bottom: 0;
              img{
                left: 20px;
                width: 100%;
                position: relative;
                top: 0px;
                left: 0px;
              }
            }
            .l-region--navigation{
              width: calc(100% - 160px);
            }
          }
          &:after{

          }
        }
      }
      .l-main{

        .l-region--full-width-top{

        }
      }
      footer{

        .main-footer-container{
          .l-region--footer-col-1{
            @include span(1 of 2(gutters: 0.05));
          }
          .l-region--footer-col-2{
            @include span(1 of 2(gutters: 0.05) last);
          }
          .l-region--footer-col-3{
            @include span(1 of 2 (gutters: 0.05));
            margin-top: 50px;
          }
          .l-region--footer-col-4{
            @include span(1 of 2 (gutters: 0.05) last);
            margin-top: 50px;
          }
        }
        .l-region--copyright{

        }
      }
    }
  }
}
@include breakpoint($mobile){
  body{

    .l-page{
      header{

        .header-top{

          .l-region--above-header{
            padding: 0;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
        .l-branding{
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
          margin-left: 5px;
          margin-right: 5px;
          &:before{

          }
          .header-main{

            .site-logo{
              width: 150px;
              background: none;
              padding: 0;
              bottom: 0;
              img{
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
              }
            }
            .l-region--navigation{
              width: calc(100% - 150px);

            }
          }
          &:after{

          }
        }
      }
      .l-main{
        main.l-content{
          padding-bottom: 0;
        }
        .l-region--full-width-top{

        }
      }
      footer{

        .main-footer-container{
          max-width: 233px;
          margin: auto;
          padding: 60px 0 30px;
          .l-region--footer-col-1{
            @include span(12);
          }
          .l-region--footer-col-2{
            @include span(12);
            margin-top: 50px;
          }
          .l-region--footer-col-3{
            @include span(12);
            margin-top: 50px;

          }
          .l-region--footer-col-4{
            @include span(12);
            margin-top: 50px;

          }
        }
        .l-region--copyright{
          max-width: 258px;
          margin: auto;
        }
      }
    }
  }
}