#views-exposed-form-machinery-page,
#views-exposed-form-machinery-page-2,
#views-exposed-form-machinery-page-3 {
  label[for="edit-sort-bef-combine"]{
    display: none;
  }
}

@include breakpoint(min-width $mobile){
  #category-facet{
    .select_filters{
      min-width: 228px;
    }
  }
}

@include breakpoint($tablet $mobile +1){
  #category-facet{
    .select_filters{
      min-width: 275px;
    }
  }
}
