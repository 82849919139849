#block-webform-client-block-954{
  display: none;
  &.visible_form{
    position: fixed;
    display: block;
    width: 100%;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    .block__content{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 500px;
      background-color: #fff;
      padding: 25px 30px;
      box-shadow: 0 0px 20px #000000;
      form{
        .form-item{
          margin-right: 20px;
          width: calc(50% - 20px);
          display: inline-block;
          float: left;
          padding-right: 0;
          input{
            width: calc(100% - 20px);
          }
          &.webform-component--message{
            width: 100%;
            margin-right: 0;
          }
          &.webform-component--email{
            width: 50%;
            margin-right: 0;
          }
          &.webform-component--thema{
            width: 50%;
            margin-right: 0;
          }
          &.webform-component--selida-proeleysis{
            display: none;
          }
        }
        .form-actions{
          text-align: center;
          width: 100%;
          display: inline-block;
          #google_recaptcha_webform_client_form_954{
            div{
              margin: auto;
              margin-bottom: 20px;
              margin-top: 6px;
            }
          }
        }
        .webform-component--forma-endiaferontos{
          width: 100%;
          margin-right: 0;

          p{
            .forma-endiaferontos-close-btn{
              position: relative;
              width: 5%;
              float: right;
              font-size: 0px;
              &:before{
                content: '';
                position: absolute;
                display: inline-block;
                background-color: #ff9933;
                width: 3px;
                height: 30px;
                right: 0px;
                top: -15px;
                border-radius: 10px;
                transform: rotate(45deg);
                cursor: pointer;
              }
              &:after{
                content: '';
                position: absolute;
                display: inline-block;
                background-color: #ff9933;
                width: 3px;
                height: 30px;
                right: 0px;
                border-radius: 10px;
                top: -15px;
                transform: rotate(-45deg);
                cursor: pointer;
              }
            }
          }
          h3{
            font-size: 20px;
            color: #ff9933;
            margin-bottom: 35px;
            width: 95%;
            float: left;
          }
        }
      }
    }
  }
  @include breakpoint($mobile){
    &.visible_form {
      .block__content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
        box-shadow: none;
        transform: none;
        width: auto;
        overflow: auto;
      }
    }
  }
  @include breakpoint($small_mobile){
    &.visible_form {
      .block__content {
        form {
          .form-item {
            input {
            }
            &.webform-component--message {
            }
            &.webform-component--onomateponymo,
            &.webform-component--email,
            &.webform-component--telephone,
            &.webform-component--thema {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
