
.block__content{
  .company-products-tabs{
    width: 100%;
    display: inline-block;
    margin-bottom: -5px;
    .company-producttab-left{
      @include span(6);
      margin-bottom: 30px;
      p{
        padding-right: 40px;
        padding-left: 40px;
        width: calc(100% - 80px);
        &:first-of-type{
        }
      }
      ul{
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 40px;
        width: calc(100% - 80px);
      }
      &.left-under{
        float: right;
        p{
        }
      }
    }
    .company-producttab-right{
      @include span(6 last);
      img{
        width: 100%;
      }
      &.right-under{
        float: left;
      }
    }
    &.rend{
      .left-under{
        ul{
          margin-top: 40px;
        }
      }
    }
  }
  @include breakpoint($mobile){
    .company-products-tabs {
      .company-producttab-left {
        clear: both;
        width: 100%;
      }
      .company-producttab-right {
        clear: both;
        width: 100%;
      }
    }
  }
}