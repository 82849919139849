#main-menu{
  display: inline-block;
  float: right;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    float: left;
    @media(min-width: 1350px){
      margin-left: 55px;
    }
  }
  position: initial;
  .block__title{
    display: none;
  }
  .om-menu{
    display: inline-block;
    margin: 0;
    vertical-align: bottom;
    height: initial;
    > li{
      display: inline-block;
      padding: 0 0 11px;
      float: left;
      margin: 0;
      height: initial;
      &:first-child,
      &:nth-child(2){
        position: initial;
        .om-maximenu-content{
          left: -15px;
          right: -24px;
          top: calc(100% - 10px);
        }
        .om-maximenu-middle-right{
          padding: 20px;
        }
      }
      &:nth-child(3){
        .om-link{
          @media(min-width: 1024px){
            pointer-events: none;
          }
        }
        .om-maximenu-content{
          min-width: 236px;
          .menu{
            padding-top: 12px;
            padding-bottom: 12px;
            > li{
              a{
                padding: 8px 24px;
                &:before,
                &:after{
                  display: none;
                }
              }
            }
          }
        }
      }
      &:nth-child(6){
        .om-link{

        }
          .om-maximenu-content{
            min-width: 334px;
            .menu{
              padding-top: 12px;
              padding-bottom: 12px;
              > li{
                a{
                  padding: 8px 24px;
                  &:before,
                  &:after{
                    display: none;
                  }
                }
              }
              &.submenusec{
                padding-left: 20px;
              }
              &.submenu,
              &.submenusec{
                @media(min-width: 1024px){
                  margin-top: -15px;
                }
                padding-top: 0px;
                padding-bottom: 0px;
                @media(min-width: 1024px){
                  visibility: hidden;
                  opacity: 0;
                  height: 0;
                }
                > li{
                  a{
                    padding-left: 45px;
                    position: relative;
                    font-size: 14px;
                    &:before,
                    &:after{
                      display: none;
                    }
                    &:before{
                      content: "";
                      position: absolute;
                      left: 26px;
                      top: 50%;
                      transform: translateY(-50%);
                      width: 7px;
                      height: 7px;
                      border-radius: 50%;
                      background-color: #f89233;
                      display: inline-block;
                    }
                  }
                }
              }
              .hmenu{
                &:hover{
                  .submenu{
                    visibility: visible;
                    opacity: 1;
                    height: 100%;
                  }
                }
              }
              .shmenu{
                &:hover{
                  .submenusec{
                    visibility: visible;
                    opacity: 1;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      &:nth-child(5){
        .om-link{
          @media(min-width: 1024px){
            pointer-events: none;
          }
        }
        .om-maximenu-content{
          min-width: 165px;
          .menu{
            padding-top: 12px;
            padding-bottom: 12px;
            > li{
              a{
                padding: 8px 24px;
                &:before,
                &:after{
                  display: none;
                }
              }
            }
          }
        }
      }
      &:nth-child(6){
        .om-link{
          @media(min-width: 1024px){
            pointer-events: none;
          }
        }
      }
      > .om-link{
        @extend .header-font;
        text-transform: uppercase;
        padding: 5px 0;
        margin: 0 11px;
        line-height: 23px;
        height: initial;
        color: $darker_gray;
        position: relative;
        font-size: 14px;
        @media(max-width: 1450px){
          margin: 0 7px;
        }
        @media(max-width: 1350px){
          font-size: 13px;
        }
        @media(max-width: 1130px){
          font-size: 12px;
          margin: 0 4px;
        }
        &:after{
          content: '';
          position: absolute;
          bottom: -11px;
          left: 0;
          right: 0;
          height: 5px;
          box-sizing: border-box;
          border-bottom: 5px solid $main_orange;
          transform: scaleX(0);
          transition: transform 0.25s ease;
        }
      }
      &.active,
      &:hover{
        > .om-link{
          &:after{
            transform: scaleX(1);
          }
        }
        &:hover{
          .om-maximenu-content{
            transition: visibility 0.25s ease, opacity 0.25s ease;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .om-maximenu-open{
    display: none;
  }
  .last.om-leaf-icon{
    float: right;
    &:hover{
      .om-maximenu-content{
        visibility: hidden;
        opacity: 0;
      }
    }
    &.active{
      .om-maximenu-content{
        visibility: visible;
        opacity: 1;
      }
    }
    > .om-link{
      @extend .sprite-search;
      color: transparent;
      font-size: 0;
      line-height: 0;
      padding: 0;
      margin: 5px 2px 5px 45px;
      cursor: pointer;
      @media(max-width: 1350px){
        margin: 5px 2px 5px 15px;
      }
      &:after{
        content: none;
      }
    }
    .om-maximenu-content{
      border: none;
      box-shadow: none;
      display: block;
      right: -10px;
      left: unset;
      width: 428px;
      top: 100%;
    }
    .om-maximenu-middle{
      background-color: transparent;
    }
    .om-maximenu-middle-right{
      padding: 0;
      box-shadow: none;
    }
    .block{
      position: relative;
      visibility: hidden;
      background-color: $totally_white;
      padding: 10px;
      transition: visibility 0.25s linear, opacity 0.25s ease;
      border: 2px solid $normal_gray;
      border-top: none;
      opacity: 0;
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.18);
      &.active {
        visibility: visible;
        transform: initial;
        z-index: 50;
        opacity: 1;
      }
      .content{
        position: relative;
        form {
          > div {
            .views-exposed-widgets {
              margin: 0;
              position: relative;
              > div {
                padding: 0;
              }
              #edit-mefibs-form-search-search-wrapper{
                float: left;
                margin-right: 10px;
                > label{
                  display: none;
                }
                .form-item{
                  input{
                    padding: 20px 9px;
                    display: inline-block;
                    &.warning{
                      border: 1px solid red;
                    }
                  }
                }
              }
              .views-submit-button{
                input{
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .view-megamenu-category-view{
    .megamenu-category-item{
      @include span(2 (gutters: 1/2));
      &:nth-child(-n + 6){
        margin-bottom: 5px;
      }
      &:nth-child(6n + 6){
        @include span(2 (gutters: 1/2) last);
        clear: right;
      }
      &:nth-child(6n + 1){
        clear: left;
      }
      &:hover{
        .megamenu-category-title{
          a{
            color: $highlighted_orange;
          }
        }
        .megamenu-category-image{
          img{
            opacity: 1;
            transform: translateX(-50%) scale(1.1);
          }
        }
      }
    }
  }
  .megamenu-category-title{
    font-weight: bold;
    a{
      display: block;
      padding-top: 5px;
      color: $darker_gray;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .megamenu-category-image{
    > a{
      display: block;
      img{
        opacity: 0.8;
        transform-origin: bottom;
        transition: opacity 0.25s ease, transform 0.25s ease;
      }
    }
  }
  .om-maximenu-middle{
    background-color: $light_gray;
  }
  .om-maximenu-content{
    top: 100%;
    transition: visibility 0.25s ease 0.2s, opacity 0.25s ease 0.2s;
    padding-top: 10px;
    display: block;
    visibility: hidden;
    opacity: 0;
    min-width: 400px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      min-width: 1070px;
    }
    @media(max-width: 1200px){
      min-width: 340px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-width: 900px;
      }
    }
  }
  .om-maximenu-middle-right{
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.18);
    padding: 0;
    .menu{
      padding: 0;
      list-style: none;
      > li{
        margin: 0;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          padding: 3px 13px;
          display: block;
          transition: background-color 0.25s ease, color 0.25s ease;
          color: $darker_gray;
          &:hover{
            background-color: $highlighted_orange;
            color: $totally_white;
          }
        }
      }
    }
  }
  .block-block-id-16,
  .block-block-id-25{
    position: relative;
    .content{
      &:after{
        content: '';
        display: table;
        clear: both;
      }
    }
    &:before{
      content: '';
      top: 20px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      border-top: 1px solid $normal_gray;
    }
    .placeholder{
      text-align: inherit;
      img{
        left: 0;
        opacity: 1;
        transform: none;
      }
    }
    h4{
      padding-top: 5px;
      a{
        display: block;
      }
    }
    .content{
      padding-top: 40px;
      > div{
        @include span(3 (gutters: 1/2));
        position: relative;
        box-sizing: border-box;
        &:last-child{
          @include span(3 (gutters: 1/2) last);
          &:before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -3.124997265624658%;
            border-left: 1px solid $normal_gray;
          }
          h4{
            padding-top: 0;
            margin-bottom: 5px;
            padding-left: 13px;
            text-transform: uppercase;
          }
          ul{
            padding: 0;
            list-style: none;
            a{
              color: $darker_gray;
              position: relative;
              &:before{
                content: "";
                position: absolute;
                left: 3px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #000;
                width: 5px;
                height: 5px;
                border-radius: 50%;
              }
              &:hover{
                color: $totally_white;
                &:before{
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
    .banner1,
    .banner2,
    .banner3{
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
      .placeholder{
        height: initial;
        img{
          position: initial;
        }
      }
    }
    .banner1{
      background-color: $totally_white;
      border: 2px solid $darker_gray;
      .placeholder{
        &:first-child{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transform-origin: left;
          left: 8px;
          display: inline-block;
          transition: top 0.5s ease, transform 0.5s ease;
        }
        &:last-child{
          text-align: right;
          height: 87px;
          img{
            position: relative;
            left: initial;
            width: 170px;
            right: -30px;
            margin-top: -6px;
            bottom: 5px;
            transition: width 0.5s ease, margin 0.5s ease;
          }
        }
      }
      &:hover{
        .placeholder{
          &:first-child{
            top: 15px;
            transform: translateY(-50%) scale(0.75);
          }
          &:last-child{
            img{
              width: 170px;
              margin-top: -16px;
              margin-bottom: -10px;
            }
          }
        }
      }
    }
    .banner2{
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f8f9+0,e5ebee+50,d7dee3+51,f5f7f9+100;White+Gloss */
      background: #f6f8f9; /* Old browsers */
      background: -moz-linear-gradient(45deg, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, #f6f8f9 0%,#e5ebee 50%,#d7dee3 51%,#f5f7f9 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, #f6f8f9 0%,#e5ebee 50%,#d7dee3 51%,#f5f7f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f8f9', endColorstr='#f5f7f9',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      border: 1px solid $normal_gray;
      .placeholder{
        img{
          position: relative;
          margin-top: -33px;
          width: 150px;
          left: 14px;
          bottom: -15px;
          transition: left 0.2s ease;
        }
      }
      h3{
        position: absolute;
        bottom: 25%;
        right: 7px;
        //text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        a{
          color: $darker_gray;
          font-size: 16px;
          transition: color 0.2s ease;
        }
      }
      &:hover{
        img{
          left: 9px;
        }
        a{
          color: $highlighted_orange;
        }
      }
    }
    .banner3{
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f1f1f1+0,999999+100 */
      background: #f1f1f1; /* Old browsers */
      background: -moz-linear-gradient(left, #f1f1f1 0%, #999999 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #f1f1f1 0%,#999999 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #f1f1f1 0%,#999999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f1f1', endColorstr='#999999',GradientType=1 ); /* IE6-9 */
      border: 2px solid $dark_gray;
      .placeholder{
        height: 87px;
        img{
          position: relative;
          margin-top: -19px;
          bottom: -12px;
          left: -15px;
          transition: bottom 0.2s ease, left 0.2s ease;
          height: 107px;
          width: auto;

        }
      }
      h3{
        position: absolute;
        top: 6px;
        right: 7px;
        padding-top: 0;
        //text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
        text-transform: uppercase;
        a{
          color: $totally_white;
          font-size: 16px;
          transition: color 0.2s ease;
        }
      }
      &:hover{
        img{
          left: -20px;
          bottom: -8px;
        }
        a{
          color: $highlighted_orange;
        }
      }
    }
  }
  .placeholder{
    display: block;
    text-align: center;
    height: 160px;
    &:before{
      content: none;
    }
    img{
      top: initial;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  #om-maximenu-mega-menu{
    position: initial;
    .om-menu-ul-wrapper{
      position: initial;
      .om-menu{
        position: initial;
      }
    }
  }


  @include breakpoint($tablet){
    .om-menu {
      > li {
        &:first-child,
        &:nth-child(2) {
          .om-maximenu-content {
            right: 0px;
            left: -172px;
          }
        }
      }
    }
  }
  @include breakpoint($tablet $mobile + 1){
    > .menu{
      padding-left: 20px;
      > li{
        > a,
        > .nolink{

          &:after{

          }
        }
        &.active-trail,
        &:hover{
          > a,
          > .nolink{
            &:after{

            }
          }
        }
      }
    }

    #search-icon{
      > .om-link{
        margin: 0 2px 0 0;
        &:after{

        }
      }
    }
  }
  @include breakpoint($bmobile){
    .block__title{
      display: block;
      position: absolute;
      right: 70px;
      top: 35px;
      z-index: 999;
      width: 35px;
      height: 30px;
      color: transparent;
      line-height: 150px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.35s ease;
      &:before,
      &:after{
        content: '';
        background: #000;
        height: 4px;
        width: 100%;
        display: inline-block;
        position: absolute;
        transition: all 0.35s ease;
      }
      &:before{
        top: 0;
        box-shadow: 0px 13px 0px 0px #000000;
      }
      &:after{
        bottom: 0;
      }
    }
    .block__content{
      display: block;
      position: fixed;
      left: 100%;
      right: -100%;
      top: 0;
      bottom: 0;
      background: #FFFFFF;
      z-index: 99999998;
      padding-top: 50px;
      transition: all 0.35s ease;
      overflow: auto;
      .om-menu{
        width: 100%;
        li{
          display: inline-block;
          width: 100%;
          padding: 0;
          margin: 0;
          span,
          a{
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0;
            border-bottom: 1px solid $main_orange;
            &:after{
              display: none;
            }
            &[href="#"]{
              &:before,
              &:after{
                content: '';
                background: $main_orange;
                position: absolute;
              }
              &:before{
                width: 2px;
                height: 25px;
                right: 20px;
                top: 10px;
              }
              &:after{
                display: block;
                height: 2px;
                width: 25px;
                right: 8px;
                transform: none;
                left: auto;
                top: 21px;
                border: none;
              }
            }
          }
          &.last.om-leaf-icon{
            display: none;
          }
          > .om-maximenu-content {
            display: none;
            position: absolute;
            opacity: 0;
            visibility: hidden;
          }
          &.g-active{
            padding-bottom: 44px;
            .om-maximenu-content {
              display: block;
              position: relative;
              padding: 0;
              width: 100%;
              left: 0;
              right: 0;
              visibility: visible;
              opacity: 1;
              //top: 44px;
              .om-maximenu-middle{
                float: left;
                width: 100%;
              }
              .om-maximenu-middle-right {
                padding: 5px;
                padding-left: 20px;
                top: 44px;
                .view-megamenu-category-view {
                  .view-content {
                    .megamenu-category-item {
                      display: inline-block;
                      width: 100%;
                      .megamenu-category-image {
                        display: none;
                      }
                    }
                  }
                }
                .block-block-id-16,
                .block-block-id-25{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    &.active{
      .block__title{
        display: block;
        position: fixed;
        right: 5px;
        top: 5px;
        z-index: 99999999;
        width: 38px;
        height: 40px;
        color: transparent;
        line-height: 150px;
        overflow: hidden;
        &:before,
        &:after{
          content: '';
          background: #000;
          top: 20px;
          height: 4px;
          width: 100%;
          display: inline-block;
          position: absolute;
          box-shadow: none;
        }
        &:before{
          transform: rotate(45deg);
        }
        &:after{
          transform: rotate(-45deg);
        }
      }
      .block__content{
        left: 0;
        right: 0;
      }
    }
  }
}

.search-mobile{
  display: none;
}
@include breakpoint($bmobile){
  .search-mobile{
    display: block;
    .search-mobile-label{
      display: block;
      position: absolute;
      right: 10px;
      top: 35px;
      z-index: 998;
      width: 35px;
      height: 30px;
      color: transparent;
      line-height: 150px;
      overflow: hidden;
      &:before,
      &:after{
        content: '';
        display: inline-block;
        position: absolute;
      }
      &:before{
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 3px solid #000000;
      }
      &:after{
        bottom: 8px;
        right: 8px;
        height: 3px;
        width: 12px;
        background: #000;
        transform: rotate( 45deg );
      }
    }
    > .content{
      display: none;
      &.active{
        position: absolute;
        top: 0;
        left: -15px;
        right: -15px;
        background: #FFFFFF;
        display: inline-block;
        z-index: 9999999;
        padding-left: 15px;
        padding-right: 80px;
        padding-bottom: 4px;
        padding-top: 24px;
      }
      [for="edit-mefibs-form-search-search"]{
        color: transparent;
        line-height: 140px;
        height: 40px;
        overflow: hidden;
        width: 40px;
        position: absolute;
        right: 28px;
        top: 28px;
        z-index: 998;
        &:before,
        &:after{
          content: '';
          background: #000;
          top: 20px;
          height: 4px;
          width: 100%;
          display: inline-block;
          position: absolute;
        }
        &:before{
          transform: rotate(45deg);
        }
        &:after{
          transform: rotate(-45deg);
        }
      }
      #edit-mefibs-form-search-sort-wrapper{
        display: none;
      }
      #edit-mefibs-form-search-search-wrapper{
        width: calc(100% - 50px);
        margin: 0;
        padding: 0;
        input{
          width: 100%;
          height: 50px;
          box-sizing: border-box;
        }
      }
      .views-submit-button{
        width: 50px;
        height: 50px;
        margin: 0;
        padding: 0;
        position: relative;
        color: transparent;
        line-height: 150px;
        overflow: hidden;
        &:before,
        &:after{
          content: '';
          display: inline-block;
          position: absolute;
        }
        &:before{
          top: 12px;
          left: 12px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 3px solid $main_orange;
        }
        &:after{
          top: 30px;
          left: 26px;
          height: 3px;
          width: 12px;
          background: $main_orange;
          transform: rotate( 45deg );
        }
        .form-submit{
          padding: 0;
          color:transparent;
          margin: 0;
          line-height: 150px;
          height: 50px;
          width: 50px;
          z-index: 99999;
        }
      }
    }
  }
}
