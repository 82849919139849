.sprite {
  background-image: url('/sites/all/themes/kolibioti/images/sprites.png');
  background-repeat: no-repeat;
  display: block;
}

.sprite-angle-bigger-left {
  @extend .sprite;
  width: 7px;
  height: 12px;
  background-position: -15px -15px;
}

.sprite-angle-bigger-right {
  @extend .sprite;
  width: 7px;
  height: 12px;
  background-position: -52px -15px;
}

.sprite-angle-down {
  @extend .sprite;
  width: 10px;
  height: 7px;
  background-position: -89px -15px;
}

.sprite-angle-left {
  @extend .sprite;
  width: 6px;
  height: 10px;
  background-position: -129px -15px;
}

.sprite-angle-right {
  @extend .sprite;
  width: 6px;
  height: 10px;
  background-position: -165px -15px;
}

.sprite-big-dot {
  @extend .sprite;
  width: 13px;
  height: 13px;
  background-position: -201px -15px;
}

.sprite-checkmark {
  @extend .sprite;
  width: 12px;
  height: 9px;
  background-position: -244px -15px;
}

.sprite-chevron-down {
  @extend .sprite;
  width: 16px;
  height: 9px;
  background-position: -244px -54px;
}

.sprite-chevron-right {
  @extend .sprite;
  width: 9px;
  height: 16px;
  background-position: -15px -93px;
}

.sprite-chevron-up {
  @extend .sprite;
  width: 16px;
  height: 9px;
  background-position: -54px -93px;
}

.sprite-dot {
  @extend .sprite;
  width: 10px;
  height: 10px;
  background-position: -100px -93px;
}

.sprite-dot-active {
  @extend .sprite;
  width: 10px;
  height: 10px;
  background-position: -140px -93px;
}

.sprite-facebook {
  @extend .sprite;
  width: 8px;
  height: 16px;
  background-position: -180px -93px;
}

.sprite-file-pdf {
  @extend .sprite;
  width: 21px;
  height: 19px;
  background-position: -218px -93px;
}

.sprite-gear {
  @extend .sprite;
  width: 54px;
  height: 58px;
  background-position: -15px -139px;
}

.sprite-inactive-angle-bigger-left {
  @extend .sprite;
  width: 7px;
  height: 12px;
  background-position: -99px -142px;
}

.sprite-inactive-angle-bigger-right {
  @extend .sprite;
  width: 7px;
  height: 12px;
  background-position: -136px -142px;
}

.sprite-load-capacity {
  @extend .sprite;
  width: 22px;
  height: 26px;
  background-position: -173px -142px;
}

.sprite-long-arrow-right {
  @extend .sprite;
  width: 49px;
  height: 16px;
  background-position: -99px -198px;
}

.sprite-max-height {
  @extend .sprite;
  width: 26px;
  height: 22px;
  background-position: -225px -142px;
}

.sprite-phone {
  @extend .sprite;
  width: 12px;
  height: 23px;
  background-position: -225px -194px;
}

.sprite-rent {
  @extend .sprite;
  width: 57px;
  height: 58px;
  background-position: -290px -15px;
}

.sprite-search {
  @extend .sprite;
  width: 23px;
  height: 23px;
  background-position: -281px -103px;
}

.sprite-showroom {
  @extend .sprite;
  width: 57px;
  height: 49px;
  background-position: -281px -156px;
}

.sprite-used {
  @extend .sprite;
  width: 81px;
  height: 77px;
  background-position: -267px -235px;
}

.sprite-white-long-arrow-right {
  @extend .sprite;
  width: 49px;
  height: 16px;
  background-position: -15px -235px;
}

.sprite-youtube {
  @extend .sprite;
  width: 20px;
  height: 15px;
  background-position: -334px -103px;
}
