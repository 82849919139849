#quicktabs-company_tabs{
  margin-top: 100px;
  ul.quicktabs-tabs{
    margin: 0;
    padding: 5px 0px;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    text-align: center;
    li{
      padding: 0;
      margin: 0 37px 0 0;
      display: inline-block;
      position: relative;
      &.active{
        a{
          color: #333333;
          &:after{
            right: 0;
            left: 0;
          }
        }
      }
      a{
        color: #ff9933;
        display: inline-block;
        padding: 8px 0;
        text-transform: uppercase;
        transition: color 0.25s ease;
        font-size: 12px;
        font-weight: 700;
        &:after{
          content: '';
          display: block;
          height: 2px;
          position: absolute;
          right: 50%;
          bottom: 0;
          left: 50%;
          background-color: #ff9933;
          transition: right 0.25s ease, left 0.25s ease;
        }
      }
      &:hover{
        &:not(.active){
          a{
            color: #f58220;
            &:after{
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}