.filter-dropdown{
  position: relative;
  display: inline-block;
  margin-right: 60px;
  vertical-align: bottom;
  .block__title{
    margin-bottom: 10px;
    vertical-align: bottom;
  }
  &.active{
    .block__content{
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
      ul,
      form{
        opacity: 1;
        visibility: visible;
        transform: translateY(100%);
        box-shadow: 0 9px 18px rgba(0, 0, 0, 0.2);
      }
    }
    .select_filters{
      &:after{
        transform: translateY(-50%) rotateX(180deg);
      }
    }
  }
  .block__content{
    border: 2px solid $normal_gray;
    display: inline-block;
    transition: box-shadow 0.25s ease;
    vertical-align: bottom;
  }
  &.empty-facet p,
  .select_filters{
    cursor: pointer;
    line-height: 1;
    padding: 14px 48px 14px 9px;
    display: inline-block;
    position: relative;
    min-width: 111px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    &:after{
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      transition: transform 0.25s ease;
      @extend .sprite-chevron-down;
    }
  }
  &.empty-facet p{
    cursor: default;
    &:after{
      content: none;
    }
  }
  ul:not(.contextual-links),
  form{
    padding: 5px 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 0.25s ease, transform 0.25s ease, visibility 0.25s ease, box-shadow 0.25s ease;
    background-color: $light_gray;
    border: 2px solid $normal_gray;
    border-top: none;
    z-index: 6;
  }
  .facetapi-limit-link{
    display: none;
  }
  .facetapi-search-api-ranges-ui-checkbox-links,
  .facetapi-facetapi-checkbox-links{
    li{
      position: relative;
      text-indent: 30px;
      margin-bottom: 0;
      padding: 5px 9px;
      @extend .small-text;
      line-height: 18px;
      input[type='checkbox']{
        display: none;
        & + .facetapi-checkbox{
          text-indent: 0;
          display: inline-block !important;
          margin-right: 0;
          color: inherit;
          &:before{
            content: '';
            position: absolute;
            top: 5px;
            left: 9px;
            display: block;
            width: 16px;
            height: 16px;
            background-color: $totally_white;
            border: 2px solid $normal_gray;
          }
          &:after{
            content: '';
            position: absolute;
            top: 10px;
            left: 14px;
            display: block;
            @extend .sprite-checkmark;
            opacity: 0;
            transition: opacity 0.1s ease;
          }
        }
        &:checked + .facetapi-checkbox{
          color: transparent;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          &:after{
            opacity: 1;
          }
        }
      }
      .facetapi-checkbox{
        @extend .small-text;
        line-height: 18px;
      }
    }
  }
  &.edit-sort{
    .views-exposed-widget{
      padding: 0;
      float: none;
    }
    .views-exposed-widgets{
      margin: 0;
    }
    .bef-select-as-radios{
      > .form-item-sort{
        > label{
          display: none;
        }
      }
    }
    .form-item{
      position: relative;
      input[type="radio"]{
        display: none;
        &:checked + label{
          &:after{
            opacity: 1;
          }
        }
      }
      label{
        @extend .small-text;
        font-weight: 400;
        line-height: 18px;
        padding: 5px 9px 5px 36px;
        display: block;
        cursor: pointer;
        &:before{
          content: '';
          position: absolute;
          top: 5px;
          left: 9px;
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: $totally_white;
          border: 2px solid $normal_gray;
        }
        &:after{
          content: '';
          position: absolute;
          top: 10px;
          left: 14px;
          display: block;
          @extend .sprite-dot;
          opacity: 0;
          transition: opacity 0.1s ease;
        }
      }
    }
  }
}
@include breakpoint($tablet $mobile + 1){
  .filter-dropdown{
    margin-right: 10px;
    &.max-height-filter{
      margin-right: 0;
    }
    &.empty-facet p,
    .select_filters{
      min-width: 125px;
    }
  }
}
@include breakpoint($mobile){
  .filter-dropdown{
    margin-right: 0;
    width: 100%;
    .block__title{
      margin-top: 10px;
    }
    .block__content{
      width: 100%;
    }
    &.empty-facet p,
    .select_filters{
      min-width:unset;
      width: calc(100% - 56px);
    }
    &#edit-sort{
     .block__title{
       width: 105px;
       float: left;
       padding-right: 0;
     }
      .block__content{
        width: calc(100% - 114px);
        float: right;
      }
    }
  }
}