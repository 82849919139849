#block-block-10,
#block-block-11,
#block-block-12{
  margin-top: 100px;
  .block__title{
    text-align: center;
    font-size: 30px;
    color: #333333;
    letter-spacing: 0.8px;
    line-height: calc(1em + 7px);
    position: relative;
    width: fit-content;
    margin: auto;
    margin-bottom: 60px;
    &:before{
      content: '';
      position: absolute;
      bottom: -16px;
      left: 50%;
      width: 40%;
      right: 0;
      height: 3px;
      box-sizing: border-box;
      border-bottom: 3px solid #ff9933;
      transform: translateX(-50%);
    }
  }
}
.block__content{
  .company-products{
    width: 100%;
    display: inline-block;
    .company-product-left{
      @include span(6);
      p{
        padding-right: 40px;
        width: calc(100% - 40px);
        &:first-of-type{
          margin-top: 40px;
        }
      }
      ul{
        margin-top: 0;
        margin-bottom: 0;
        padding-right: 40px;
        width: calc(100% - 80px);
      }
    }
    .company-product-right{
      @include span(6 last);
      img{
        width: 100%;
      }
    }
    .company-product-full{
      margin-top: 30px;
      width: 100%;
      display: inline-block;
    }
    &.anupsotiko{
      .company-product-left{
        @include span(6 last);
        p{
          padding-left: 40px;
          width: calc(100% - 40px);
          &:first-of-type{
            margin-top: 40px;
          }
        }
        ul{
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 40px;
          width: calc(100% - 80px);
        }
      }
      .company-product-right{
        @include span(6);
        img{
          width: 100%;
        }
      }
    }
  }
  @include breakpoint($mobile){
    .company-products.anupsotiko,
    .company-products{
      .company-product-left {
        clear: both;
        width: 100%;
      }
      .company-product-right {
        clear: both;
        width: 100%;
      }
    }
  }
}