@import "susy";
@import "breakpoint";
$site-container: 1392px;

$susy: (
  columns: 12,
  gutters: 0,
  container: $site-container,
  global-box-sizing: border-box,
  math: fluid
);

@include breakpoint-set('default feature', max-width);

@mixin container_p($max-width: $site-container, $padding: 15px){
  @include breakpoint($max-width + ($padding * 2)){
    padding-left: $padding;
    padding-right: $padding;
  }

  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin container_m($max-width: $site-container, $margin: 15px){
  @include breakpoint($max-width + ($margin * 2)){
    margin-left: $margin;
    margin-right: $margin;
  }
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin placeholder($color: lighten(black, 30%), $opacity: 1, $font-weight: 300){
  &::-webkit-input-placeholder { // Chrome/Opera/Safari
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
  &::-moz-placeholder { // Firefox 19+
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
  &:-ms-input-placeholder { // IE 10+
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
  &:-moz-placeholder { // Firefox 18-
    color: $color;
    opacity: $opacity;
    font-weight: $font-weight;
  }
}


$desktop_first: 1500px;
$desktop_second: 1300px;
$tablet: 1200px;
$bmobile: 1023px;
$mobile: 767px;
$small_mobile: 414px;

$darker_gray: #333333;
$main_orange: #ff9933;
$saturated_orange: #f58220;
//$highlighted_orange: lighten(desaturate($main_orange, 10%), 15%);
$highlighted_orange: $saturated_orange;
$totally_white: #fff;
$light_gray: #f1f1f1;
$normal_gray: #cccccc;
$dark_gray: #999999;
